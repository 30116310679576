import(/* webpackMode: "eager" */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/(default)/components/main/atoms/AuthorsList.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/(default)/components/main/atoms/NewsletterForm.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/(default)/components/main/SectionDivider.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Analytics/Google/GAObserver.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Article/ArticleSimple.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Article/atoms/badges/PremiumBadge.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Article/atoms/CoverPhoto.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Article/styles/authorIntro.module.scss");
import(/* webpackMode: "eager" */ "/app/src/app/components/Form/Checkbox.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Form/EmailInput.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Form/IconInput.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Form/PasswordInput.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Lazy/LazyArticles.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Lazy/LazyComponent.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Lazy/LazyLoader.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Recommended/atoms/RecommendedSlider.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Thumbnail/Thumbnail.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/lib/root/scripts.tsx")